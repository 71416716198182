@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;600;800&display=swap'); */

@layer base {
    body{
        /* @apply font-[Raleway]; */
        @apply bg-[#F2F2F2];
    }
    li {
        
        @apply px-4;
        @apply cursor-pointer
    }
    /* .text-primary {
        color: #EE674A;
    }
    .bg-primary {
        background-color: #EE674A;
    } */
    .shadow-primary {
        box-shadow: 5px 5px black;
    }
    .shadow-primary-dark {
        box-shadow: 5px 5px white;
    }
}

@layer components {
    
  }